<template>
  <v-container>
    <v-card class="semester-select">
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="12" md="6" lg="3">
            <v-select
              v-model="selectSemester"
              :items="semesters"
              item-text="nameZh"
              item-value="id"
              label="请选择学期"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="3" lg="2">
            <v-btn
              color="primary"
              elevation="2"
              @click="getCourse()"
              :loading="semisterButtonLoading"
              >获取该学期课程信息</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- 顶部 课程组+1 按钮 -->
    <v-row
      v-show="!(Array.prototype.isPrototypeOf(courses) && courses.length === 0)"
    >
      <v-col class="text-right">
        <v-btn color="primary" elevation="2" @click="coursesArranging.push([])">
          添加课程组
        </v-btn>
      </v-col>
    </v-row>
    <!-- 待排课程组 -->
    <v-row
      v-show="!(Array.prototype.isPrototypeOf(courses) && courses.length === 0)"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(card, index) in coursesArranging"
        :key="index"
      >
        <v-card>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">课程名</th>
                    <th class="text-left">任课老师</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-if="
                      Array.prototype.isPrototypeOf(card) && card.length === 0
                    "
                  >
                    <v-container><p>请添加课程</p></v-container>
                  </template>
                  <template v-else>
                    <tr v-for="item in card" :key="item.id">
                      <td>{{ item.course.cn }}</td>
                      <td>{{ item.teacherAssignmentList[0].cn || "" }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end">
              <v-col class="text-right" style="padding-top: 0"
                ><v-btn icon @click.stop="showCoursesDialog(card, index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click.stop="coursesArranging.splice(index, 1)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- 点击排课 按钮 -->
    <v-row
      v-show="
        !(
          Array.prototype.isPrototypeOf(coursesArranging) &&
          coursesArranging.length === 0
        )
      "
    >
      <v-col class="text-right">
        <v-btn color="primary" elevation="2" @click="arrangeCourses"
          >排课</v-btn
        >
      </v-col>
    </v-row>
    <!-- 排课结果 -->
    <v-row
      v-show="
        !(
          Array.prototype.isPrototypeOf(resultSchedule) &&
          resultSchedule.length === 0
        )
      "
    >
      <template v-for="(schedule, index) in resultSchedule">
        <v-col cols="12" lg="6" :key="'T' + index">
          <v-card>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">课程名</th>
                      <th class="text-left">课堂号</th>
                      <th class="text-left">任课老师</th>
                      <th class="text-left">上课时间</th>
                      <th class="text-left">考试形式</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in schedule" :key="item.id">
                      <td>{{ item.course.cn }}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ item.teacherAssignmentList[0].cn || "" }}</td>
                      <td>{{ item.dateTimePlaceText }}</td>
                      <td>{{ item.examMode.cn }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" :key="'C' + index">
          <v-card>
            <v-card-text>
              <calendar :courses="schedule" />
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <!-- 弹出式对话框 -->
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          请选择课程
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="courseSearch"
          ></v-text-field>
          <v-btn color="primary" elevation="2">刷新课程列表</v-btn>
        </v-card-title>

        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="courses"
            :search="search"
            item-key="id"
            show-select
            class="elevation-1"
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            取消
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="
              [...coursesArranging[dialogCardIndex]] = selected;
              dialog = false;
            "
          >
            完成
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Calendar from "@/components/Calendar.vue";
export default {
  name: "Home",
  components: {
    Calendar,
  },
  data() {
    return {
      dialog: false,
      semisterButtonLoading: false,
      selected: [],
      dialogCardIndex: 0,
      search: "",
      courses: [],
      semesters: [],
      selectSemester: "",
      resultSchedule: [],
      headers: [
        {
          text: "课程名",
          value: "course.cn",
        },
        {
          text: "任课老师",
          value: "teacherAssignmentList[0].cn",
          //TODO: 若没老师, 系空数组, 会报错
        },
        // {
        //   text: "时间",
        //   value: "dateTimePlaceText",
        //   filterable: false,
        // },
        {
          text: "课堂号",
          value: "code",
        },
        {
          text: "已选学生",
          value: "stdCount"
        },
        {
          text: "选课上限",
          value: "limitCount"
        }
      ],
      coursesArranging: [],
    };
  },
  mounted() {
    this.getSemester();
  },
  methods: {
    getCourse() {
      this.semisterButtonLoading = true;
      this.courses = [];
      this.coursesArranging = [];
      this.axios
        .get("https://api.jonbgua.com/?https://catalog.ustc.edu.cn/get_token")
        .then((r) => {
          var token = r.data.access_token;
          this.axios
            .get(
              `https://api.jonbgua.com/?https://catalog.ustc.edu.cn/api/teach/lesson/list-for-teach/${this.selectSemester}?access_token=${token}`
            )
            .then((r) => {
              this.courses = r.data;
              if (
                localStorage.coursesArranging &&
                localStorage.semister === String(this.selectSemester)
              ) {
                this.coursesArranging = JSON.parse(
                  localStorage.coursesArranging
                );
              }
              this.semisterButtonLoading = false;
            });
        });
    },
    getSemester() {
      this.axios
        .get("https://api.jonbgua.com/?https://catalog.ustc.edu.cn/get_token")
        .then((r) => {
          var token = r.data.access_token;
          this.axios
            .get(
              `https://api.jonbgua.com/?https://catalog.ustc.edu.cn/api/teach/semester/list?access_token=${token}`
            )
            .then((r) => {
              this.semesters = r.data.sort(compare("id"));
              this.selectSemester = this.semesters[0].id;
            });
        });
    },
    arrangeCourses() {
      localStorage.coursesArranging = JSON.stringify(this.coursesArranging);
      localStorage.semister = this.selectSemester;
      this.resultSchedule = [];
      for (let i = 0; i < this.coursesArranging[0].length; i++) {
        tryToAdd(this.resultSchedule, this.coursesArranging, [], 0, i);
      }
    },
    showCoursesDialog(card, index) {
      this.selected = [];
      this.selected = card.slice(0);
      this.search = "";
      if (card.length !== 0) {
        this.search = card[0].course.cn;
      }
      this.dialogCardIndex = index;
      this.dialog = true;
    },
  },
};

function compare(p) {
  // 这是比较函数
  return function (m, n) {
    var a = m[p];
    var b = n[p];
    return b - a; // 降序
  };
}

/**
 * @param {array} result  引用参数, 放置最终结果
 * @param {array} allCourses  引用参数, 所有待排课程
 * @param {array} arrangedCourses   应深拷贝, 该递归链的已排课程
 */
function tryToAdd(result, allCourses, arrangedCourses, cardIndex, courseIndex) {
  // 排入当前课
  var current = allCourses[cardIndex][courseIndex];
  if (no_conflict(current, arrangedCourses)) {
    var arrangedCoursesNew = arrangedCourses.slice(0);
    arrangedCoursesNew.push(current);
    // 已经排完了
    if (cardIndex === allCourses.length - 1) {
      result.push(arrangedCoursesNew);
      return true;
    } else {
      for (let i = 0; i < allCourses[cardIndex + 1].length; i++) {
        tryToAdd(result, allCourses, arrangedCoursesNew, cardIndex + 1, i);
      }
    }
  } else {
    return false;
  }
}

function get_time_pairs(dateText) {
  var re = /\d\(\d{1,2},\d{1,2}(,\d{1,2})?\)/g;
  var dnns = dateText.match(re); // Date(course)Number(course)Number: 1(1,2)
  re = /\d{1,2}/g;
  var nums;
  var result = [];

  for (let dnn of dnns) {
    nums = dnn.match(re);
    for (let i = 1; i < nums.length; i++) {
      result.push(nums[0] + "+" + nums[i]);
    }
  }
  return result;
}
function no_conflict(currentCourse, arrangedCourses) {
  var arrangedTimePairs = [];
  for (let c of arrangedCourses) {
    arrangedTimePairs = arrangedTimePairs.concat(
      get_time_pairs(c.dateTimePlaceText)
    );
  }
  var newTimePairs = get_time_pairs(currentCourse.dateTimePlaceText);
  for (let i of newTimePairs) {
    if (arrangedTimePairs.includes(i)) return false;
  }
  return true;
}
</script>

<style lang="stylus" scoped>
.courseSearch {
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-right: 15px;
}

.semester-select {
  margin: 20px 0 20px 0;
}
</style>
