<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Takasi</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item href="/" @click="drawer = false">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>主页</v-list-item-title>
            </v-list-item>

          <v-list-item href="/help" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>帮助</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer padless class="footer">
      <v-col class="text-center" cols="12">
        © 2021 <strong><a href="https://jonbgua.com">Jonbgua</a></strong> | <a href="https://support.qq.com/products/342142" target="_blank" rel="noopener noreferrer">意见反馈</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    navigateTo(route) {
      this.$router.push(route).catch(() => {});
    },
  },
};
</script>

<style lang="stylus" scoped>
.footer {
  margin-top: 50px;

  a {
    text-decoration: none;
  }
}
</style>
