<template>
  <v-sheet height="600">
    <v-calendar
      ref="calendar"
      :now="today"
      :value="today"
      :events="events"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      color="primary"
      type="week"
    ></v-calendar>
  </v-sheet>
</template>

<script>
export default {
  name: "Calandar",
  props: {
    courses: Array,
  },
  data: () => ({
    today: "2021-03-01",
  }),
  mounted() {
    this.$refs.calendar.scrollToTime("07:50");
  },
  computed: {
    events() {
      var result = [];
      for (let i of this.courses) {
        result = result.concat(class_to_calendar_objs(i))
      }
      return result;
    },
  },
};
function class_to_calendar_objs(c) {
  var re = /\d\(\d{1,2},\d{1,2}(,\d{1,2})?\)/g;
  var dnns = c.dateTimePlaceText.match(re); // Date(course)Number(course)Number: 1(1,2)
  re = /\d{1,2}/g;
  var nums;
  var classAllTime = [];
  var start, end;

  for (let dnn of dnns) {
    nums = dnn.match(re); // num = [DAY, N1, ..., Nn]
    start = day_to_date(nums[0]) + " " + class_start_time(nums[1]);
    end = day_to_date(nums[0]) + " " + class_end_time(nums[nums.length - 1]);
    classAllTime.push({
      name: c.course.cn,
      start: start,
      end: end,
    });
  }
  return classAllTime
}

function day_to_date(day) {
  return "2021-03-0" + String(day);
}
function class_start_time(n) {
  const table = {
    1: "07:50",
    2: "08:40",
    3: "09:45",
    4: "10:35",
    5: "11:25",
    6: "14:00",
    7: "14:50",
    8: "15:55",
    9: "16:45",
    10: "17:35",
    11: "19:30",
    12: "20:20",
    13: "21:10",
  };
  return table[Number(n)];
}
function class_end_time(n) {
  const table = {
    1: "08:35",
    2: "09:25",
    3: "10:30",
    4: "11:20",
    5: "12:10",
    6: "14:45",
    7: "15:35",
    8: "16:40",
    9: "17:30",
    10: "18:20",
    11: "20:15",
    12: "21:05",
    13: "21:55",
  };
  return table[Number(n)];
}
</script>

<style lang="stylus" scoped></style>